import React, { Component } from "react";
import "./App.scss";
import Zbutton from "./buttons/Zbutton";
import Color from "./colors/color";
import "antd/dist/antd.css";

import { Button, Form, Input, Select, Drawer, Switch } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import TextArea from "./buttons/TextArea";

const { Item } = Form;
const { Option } = Select;

const colors = [
  "blueKF",
  "volcanoKF",
  "orangeKF",
  "dastredKF",
  "goldKF",
  "yellowKF",
  "limeKF",
  "greenKF",
  "cyanKF",
  "geekblueKF",
  "purpilKF",
  "magentaKF",
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class App extends Component {
  state = {
    buttonColor: "blueKF",
    site: "https://zapros.org",
    provider: 14129,
    buttonText: "Забронировать онлайн",
    vertical: false,
    style: "default",
    visible: false,
    integration: '',
    buttonRound: true
  };

  setParams = (param) => {
    var tmp = [];
    var tmp2 = [];
    var params = {};
    var get = decodeURI(window.location.search);

    if (get !== "") {
      tmp = get.substr(1).split("&");
      for (var i = 0; i < tmp.length; i++) {
        tmp2 = tmp[i].split("=");
        params[tmp2[0]] = tmp2[1];
      }
    }

    if (param) {
      return params[param];
    } else {
      return false;
    }
  };

  componentWillMount = () => {
    let url = this.setParams("url");
    let pid = this.setParams("id");
    let color = this.setParams("color");
    let style = this.setParams("style");
    let text = this.setParams("text");

    color = color ? color + 'KF' : this.state.buttonColor;

    this.setState({
      site: url || this.state.site,
      provider: +pid || this.state.provider,
      buttonColor: color,
      style: style || this.state.style,
      buttonText: text || this.state.buttonText
    });
  };
  changeColor = (name) => {
    this.setState({
      buttonColor: name,
    });
  };
  changeView = () => {
    this.setState({
      vertical: !this.state.vertical,
    });
  };
  onChangeSite = (event) => {
    if (event.keyCode === 13) {
      this.setState({
        site: event.target.value,
      });
    }
  };
  onChangeId = (event) => {
    this.setState({
      provider: event.target.value,
    });
  };

  onChangeButtonText = (e) => {
    this.setState({
      buttonText: e.target.value,
    });
  };

  myFunction = () => {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    document.getElementById("provider").focus();

    /* Alert the copied text */
    alert("Код скопирован");
  };

  handleChangeStyle = (value) => {
    this.setState({
      style: value,
    });
  };

  onChangeIntegration = (e) => {
    this.setState({
      integration: e.target.value,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onChangeSwich = (checked) => {
    this.setState({
      buttonRound: checked
    })
  }

  getURL = () =>{
    let color = this.state.buttonColor;
    color = color.substring(0, color.length - 2);
    return "https://button.zapros.org/" 
    + "?url=" + this.state.site 
    + "&id=" + this.state.provider 
    + "&color=" + color 
    + "&style=" + this.state.style
    + "&text=" + this.state.buttonText;
  }

  render() {
    return (
      <div className="App">
        <div className="settings-button" onClick={this.showDrawer}>
          <SettingOutlined style={{ fontSize: '24px', color: '#08c' }}/>
        </div>
        <Drawer
          width={450}
          title="Настройка Виджета"
          mask={false}
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          key="left"
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button type="primary" onClick={this.onClose}>Закрыть</Button>
            </div>
          }
        >
          <div className="settings open">
            <Form {...layout}>
              <Item label="Пример сайта" name="site">
                <Input
                  defaultValue={this.state.site}
                  onKeyUp={this.onChangeSite}
                  id="site"
                  placeholder="Введите пример сайта"
                />
              </Item>
              <Item label="Сайт интеграции" name="integration">
                <Input
                  defaultValue={this.state.integration}
                  onKeyUp={this.onChangeIntegration}
                  placeholder="например starobinleshoz.lakes.by"
                />
              </Item>
              <Item label="ID провайдера" name="providerId">
                <Input
                  defaultValue={this.state.provider}
                  onKeyUp={this.onChangeId}
                  id="provider"
                />
              </Item>
              <Item label="Текст Кнопки" name="buttonText">
                <Input
                  defaultValue={this.state.buttonText}
                  onKeyUp={this.onChangeButtonText}
                />
              </Item>
              <Item label="Стиль виджета" name="style">
                <Select
                  defaultValue="default"
                  onChange={this.handleChangeStyle}
                >
                  <Option value="default">По умолчанию</Option>
                  <Option value="eva">Оливковый</Option>
                  <Option value="gto">Синий</Option>
                  <Option value="vk">Голубой</Option>
                  <Option value="ihunt">Зеленый</Option>
                </Select>
              </Item>
              <Item label="Вид кнопки">
                <Switch checkedChildren="круглая" unCheckedChildren="стандартная" defaultChecked onChange={this.onChangeSwich}/>
              </Item>
              <Item>
                <Button type="primary" onClick={this.changeView}>
                  {!this.state.vertical ? "вертикально" : "горизонтально"}
                </Button>
                &nbsp;
                <Button type="default" onClick={this.myFunction}>
                  Копировать код
                </Button>
              </Item>
            </Form>
            {this.state.buttonRound ? 
            <div className="colors" >
              {colors.map((paramname, i) => (
                <Color
                  name={paramname}
                  handleClick={() => this.changeColor(paramname)}
                  key={i + paramname}
                />
              ))}
            </div> : null }

            <div style={{marginBottom: '15px'}}>
              <a href={this.getURL()} target="_blank">{this.getURL()}</a>
            </div>
            <div className="highlight">
              <TextArea
                provider={this.state.provider}
                text={this.state.buttonText}
                view={this.state.vertical}
                sitestyle={this.state.style}
                bcolor={this.state.buttonColor}
                integration={this.state.integration}
                round={this.state.buttonRound}
              />
            </div>
          </div>
        </Drawer>

        <Zbutton
          color={this.state.buttonColor}
          view={this.state.vertical}
          provider={this.state.provider}
          widgetstyle={this.state.style}
          integration={this.state.integration}
          round={this.state.buttonRound}
        >
          {this.state.buttonText}
        </Zbutton>
        <iframe src={this.state.site} frameBorder="0"></iframe>
      </div>
    );
  }
}

export default App;
