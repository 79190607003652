import React, { Component } from 'react';
import './round-style.scss'
import './positions.scss'
import './colors.scss'

class Zbutton extends Component {
    state = {}

    handleClick = () => {
        
        window.__zw[6] = ["cssStyle", this.props.widgetstyle];
        window.__zw[7] = this.props.view ? ["widgetView", "vertical" ] : ["widgetView", "default" ];
        if(this.props.integration){
            window.__zw[8] = ["integration", this.props.integration ];
        }

        console.log(this.props.provider);
        
        window.zs.LoadStep({ stepNumber: 1, providerId: this.props.provider, serviceId: 0, resourceId: 0 });

    }
    render() {
        let cls = '';
        if(this.props.round){
            cls = "zs-button zs-bottom-right-position " + this.props.color + "-color"
        } else {
            cls = "__zaprosWidget zs-bottom-right-position"
        }
        return (
            <div className={cls} onClick={this.handleClick}>
                <div className="text-call">
                    <span>{this.props.children}</span>
                </div>
            </div>
        );
    }
}

export default Zbutton;