import React, { Component } from 'react';

class TextArea extends Component {
    state = {}

    render() {
        let widgetView;
        if (this.props.view) {
            widgetView = "vertical";
        } else {
            widgetView = "default";
        }

        let integration = this.props.integration ? `,
            ["integration", "${this.props.integration}" ]` : ``;

        let cls = '';
        if(this.props.round){
            cls = "zs-button zs-bottom-right-position " + this.props.bcolor + "-color"
        } else {
            cls = "__zaprosWidget"
        }

        let code = `<!--ZAPROS START SCRIPT этот код вставьте в head -->
<script type="text/javascript">
var __zw = __zw || []; __zw.push(
    ["providerId", "${this.props.provider}"],
    ["widgetResourcePath", "static.zapros.by"],
    ["widgetAppServerUrl", "w.zapros.by"],
    ["officeAppServerUrl", "office.zapros.by"],
    ["httpOrHttps", "https"],
    ["widgetVersion", "11408" ],
    ["cssStyle", "${this.props.sitestyle}" ],
    ["widgetView", "${widgetView}" ]${integration}
);        
(function () {
    var a = document.createElement("script");
    a.type = "text/javascript";
    a.async = !0;
    a.src = "https://static.zapros.by/Scripts/widget/wLoader.min.js?v=11408";
    (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(a);
})();
window.addEventListener("load", function () {
    var btn = document.getElementById('zapros_booking_button');
    if(btn){btn.onclick = function(){window.zs.LoadStep({ stepNumber:1, providerId: ${this.props.provider}, serviceId: 0, resourceId:0 });}}
});
</script>
<!--ZAPROS END SCRIPT-->

<!--ZAPROS START BUTTON этот блок вставьте в body -->
<div class="${cls}" id="zapros_booking_button"><div class="text-call"><span>${this.props.text}</span></div></div>
<!--ZAPROS END BUTTON-->
` ;

            let text = code;
        return ( 
            <textarea spellcheck="false" className="ant-input" rows="15" id="myInput" value={text} />
         );
    }
}
 
export default TextArea;